.elasticity[data-v-2b163cee] {
  display: flex;
}
.age[data-v-2b163cee] {
  font-size: 12px;
}
.addIcon[data-v-2b163cee] {
  margin-bottom: 18px;
  margin-left: 20px;
  font-size: 22px;
  cursor: pointer;
}
.el-input[data-v-2b163cee] {
  width: 50%;
}
